@import "./variables.scss";

.article{
    background-color: $back-light;
    display: flex;
    justify-content: center;
    color: $black;
 
    &__container{
        max-width: $max-width;
        width: 100%;
        padding: 90px $margen-lat 50px $margen-lat;

        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
          
        justify-content: center;

        @include breakpoint-down(medium) {
          padding: 90px $margen-lat-mobil 50px $margen-lat-mobil;

        }



        &--card{


            margin: 0 20px;
            border-radius: 15px;
            color: $black;
    
            width: 620px;
            margin-bottom: 100px;

            box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10); 
                  
            &:hover{
                cursor: pointer;

                box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.10); 
            }

            @include breakpoint-down(medium) {
              width: 100%;
            }


            &-img {
                width: 620px;
                height: 320px;
                border-radius: 15px 15px 0 0;
                overflow: hidden;
      
                background-color: white;
      
                display: flex;
                justify-content: center;
                align-items: center;
      
                @include breakpoint-down(medium) {
                  width: 100%;
                  height: auto;
                }
    

                img {
                  width: 100%;
                  display: block;
                  margin: auto;
                }
                
              }


              &-content {
                background-color: $white;
                border-radius: 0 0 15px 15px;
                padding: 45px;
                min-height: 290px;
      
                @include breakpoint-down(medium) {
                  padding: 20px;
                  min-height: 190px;
                }

                h3 {
                  font-size: $card_title2;
                  text-align: center;
                  align-self: center;
                  font-weight: bold;
                }
                
                p {
                  font-size: $card_sub;
                  text-align: center;
                  line-height: 1.49;
                  align-self: center;
                }
              }



        }


    }

}