
// 375px, 768px, 1024px
$breakpoints-up: ("small": "24em", "medium": "48em", "large": "64em");

// 374px, 767px, 1023px
$breakpoints-down: ("small": "23.375em", "medium": "47.9375em", "large": "63.9375em");



@mixin breakpoint-up($size){
  @media (min-width: map-get($breakpoints-up, $size)){
    @content;
  }
}

@mixin breakpoint-down($size){
  @media (max-width: map-get($breakpoints-down, $size)){
    @content;
  }
}