@import "./variables.scss";

.footer {
  background-color: $white;
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 85px $margen-lat 45px $margen-lat;
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(medium) {

      padding: 45px $margen-lat-mobil 25px $margen-lat-mobil;
      flex-direction: column;

    }



    &--left {
        width: 35%;

        @include breakpoint-down(medium) {
          width: 100%;
          
        }

      &-logo {
        display: block;
        margin-bottom: 90px;

        @include breakpoint-down(medium) {
          margin-bottom: 20px;

        }

        img{
            width: 60px;
        }
      }

      &-links {
        font-size: 24px;
        margin-bottom: 90px;

        @include breakpoint-down(medium) {
          margin-bottom: 40px;
          
        }

        &-page{
            display: flex;
            justify-content: space-between;
            width: 390px;
            font-weight: bold;
            margin-bottom: 25px;
        }
        &-web{
            display: flex;
            width:  320px;
            margin-bottom: 30px;

            a:nth-child(1){
                margin-right: 30px;
            }
        }

        &-policies{
        }


      }





      p {
        font-size: 16px;
        font-weight: bold;
      }
    }
    &--right {
        width: 35%;

        @include breakpoint-down(medium) {
          width: 100%;
          
        }


      h5 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 35px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      &-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;

        div{
            width: 48%;
            height: 60px;
            border-radius: 11px; 
            border: 2px solid #C7C7C7;
        }


      }

      button {
        width: 100%;
        height: 60px;
        border-radius: 15px;
        margin-top: 22px;
        background-color: $secundary;
                  font-size: 28px;
          color: $white;

      }
    }





  }

  &__text {
    max-width: $max-width;
    width: 100%;
    padding: 10px $margen-lat 10px $margen-lat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-down(medium) {
      padding: 10px $margen-lat-mobil 10px $margen-lat-mobil;

    }


    p{
      margin-bottom: 10px;
      font-size: 16px;
      color: $black;
      text-align: justify;
    }



  }


  &__mark {
    max-width: $max-width;
    width: 100%;
    padding: 25px $margen-lat 25px $margen-lat;

    font-size: 16px;
    font-weight: bold;
}

}
