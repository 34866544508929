@import "./variables.scss";

.ind-header {
  background-color: $background;
  display: flex;
  justify-content: center;
  color: $white;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 90px $margen-lat 50px $margen-lat;

    display: flex;

    @include breakpoint-down(large) {
      flex-direction: column;
      padding: 60px $margen-lat 40px $margen-lat;
    }


    @include breakpoint-down(medium) {
      padding: 50px $margen-lat-mobil 20px $margen-lat-mobil;
      flex-direction: column;

    }


    &--text {
      width: 50%;
      @include breakpoint-down(large) {
        width: 100%;

      }

      padding-right: 30px;
      h2 {
        font-size: $header_title;
        line-height: 1.333;
        margin-bottom: 25px;
      }
      p {
        font-size: $header_parag;
        margin-top: 50px;
        font-weight: normal;
        margin-bottom: 30px;
      }

      a {
        display: inline-block;
        text-align: center;
        font-size: 28px;
        color: $white;
        width: 50%;
        height: 60px;
        border-radius: 15px;
        margin-top: 22px;
        background-color: $secundary;
        padding: 9px;

      }
    }
    &--img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;

      @include breakpoint-down(large) {
        width: 100%;
        margin-top: 50px;

      }

      img {
        width: 80%;
        border-radius: $border-card;
        
        @include breakpoint-down(large) {
          width: 100%;
        }


      }
    }
  }
}
