//globales
$max-width: 1450px;
$color-bg: rgba(0,0,0,1);
$margen-lat: 52px;
$margen-lat-mobil: 20px;


//colores
$white: rgb(250, 250, 250);
$black: rgb(5,5,5);
$background: rgb(26,15,49);
$back-mid: rgb(105,79,161);

$back-light: rgb(239,239,245);
$secundary: rgb(245,62,113);

//font size
$header_title:2.7rem;
$header_sub:1.1rem;
$header_parag:0.9rem;

$sec_title:3.7rem;
$sec_sub:1.4rem;

$card_title:2.3rem;
$card_title2:1.5rem;
$card_sub:1.2rem;

$cont_tit3:1.25rem;


//border-radius
$border-card:20px;




