.init{
    display: flex;
    justify-content: center;

    &__c{
        // width: 100%;
        // padding: 0 $margen-lat 0 $margen-lat;
    }
}

html {
    
    @include breakpoint-down(medium) {
        font-size: 14px;
    }

    @include breakpoint-up(medium) {
        font-size: 16px;
    }

    @include breakpoint-up(large) {
        font-size: 18px;
    }    
}


.gl-goTop {
    color: $secundary;
    font-size: 39px;
  
    &--cont {
      background-color: rgb(246, 246, 246) !important;
      box-shadow: 0 0px 7px 7px rgba(1, 1, 1, 0.075) !important;
      padding: 3px !important;
      width: 45px !important;
      height: 45px !important;
      bottom: 110px !important;
      right: 43px !important;
      
      border-radius: 50px !important;
  

      @include breakpoint-down(large) {
        bottom: 105px !important;
        right: 38px !important;
    }


    @include breakpoint-down(medium) {
        bottom: 95px !important;
        right: 35px !important;
    }


    }
  }

