@import "./variables.scss";

.article_gen {

  
      &--img {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
  

        img {
          width: 100%;
          border-radius: $border-card;
        }
      }
  
      &--video {
        margin: 0 auto;
        display: flex;
        justify-content: left;
        border-radius: $border-card;
        margin-bottom: 20px;
  
        iframe {
          width: 900px;
          height: 500px;
          border-radius: $border-card;
        }
      }
  
      &--imgInt {
        display: flex;
        justify-content: left;
        align-items :center;
        margin-bottom: 20px;
  
        img {
          width: 70%;
          border-radius: $border-card;
        }
      }
  
      &--tit {
        font-size: $card_title;
        line-height: 1.22;
        text-align: left;
        margin-bottom: 30px;

      }

      &--tit2 {
        font-size: $card_title2;
        line-height: 1.22;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 20px;

      }

      &--tit3 {
        font-size: $cont_tit3;
        line-height: 1.22;
        text-align: left;
        margin-top:25px;
        margin-bottom:15px;

      }



  
      &--sub {
        line-height: 1.3;
        text-align: left;
        margin-bottom: 20px;
  
        & > * {
          font-weight: 600;
        }
  
      }
  
      &--txt {
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 20px;

      }
  
      &--txtLink {
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 20px;
        
  
        & a {
          color: $back-mid;
        }
      }
  
      &--dot {
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 20px;
        
        display: list-item; /* This has to be "list-item"                                          */
        margin-left: calc(30px + 1em);
  

      }
  
      &--dotLink {
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
  
        display: list-item; /* This has to be "list-item"                                          */
        margin-left: calc(30px + 1em);
        margin-bottom: 20px;
  
        & a {
          color: $back-mid;
        }
      }
  
      &--by {

        & a {
          text-decoration: underline;
        }
  
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 20px;
  

      }
  
      &--date {
        font-weight: normal;
        line-height: 1.5;
        text-align: right;
        margin-top: -30px;
        margin-bottom: 20px;
        
  
      }
  
      &--link {
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        padding: 10px 20px;
        border-radius: 10px;
        background-color: $secundary;
        color: $white;
        margin-bottom: 20px;

          &:hover {
            background-color: rgba(245,62,113,0.75);
    
          }
      }

      &--sepA{
        height: 10px;
        width: 100%;
      }

      &--sepB{
        height: 25px;
        width: 100%;
      }

      &--sepC{
        height: 40px;
        width: 100%;
      }

      &--sepD{
        height: 100px;
        width: 100%;
      }




  }