@import "./variables.scss";

.sidebar {
  width: 30%;
  height: 100%;
  position: relative;

  @include breakpoint-down(large) {
    width: 90%;
    margin: 0 auto;
  }

  &__content {
    position: sticky;
    top: 20px;
    margin-bottom: 80px;
    left: 0;

    padding: 50px 30px 30px 30px;
    background-color: $background;
    border-radius: $border-card;

    &--susc {
      h3 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        color: $white;
        text-align: center;
      }

      a {
        display: inline-block;
        font-size: 28px;
        color: $white;
        width: 100%;
        height: 60px;
        border-radius: 15px;
        margin-top: 22px;
        padding: 9px;
        background-color: $secundary;
        text-align: center;
        cursor: pointer;
      }

      p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 1.2;
        color: $white;
        text-align: left;
      }
    }
    &--blog {
      margin-top: 80px;
      cursor: pointer;

      h3 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        color: $white;
        text-align: center;
      }

      &-card {
        margin-top: 20px;
        border-radius: $border-card;
        background-color: $back-mid;
        padding: 20px;

        h4 {
          font-size: 22px;
          font-weight: bold;
          line-height: 1.1;
          color: $white;
          text-align: center;
        }
        p {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.2;
          color: $white;
          text-align: left;
        }
      }
    }
  }
}
