@import "./variables.scss";

.indicator-page {
  background-color: $back-light;
  display: flex;
  justify-content: center;
  color: $black;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 90px $margen-lat 50px $margen-lat;
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(medium) {
      padding: 90px $margen-lat-mobil 50px $margen-lat-mobil;
      flex-direction: column-reverse;

    }


    &--blog {
      width: 65%;

      @include breakpoint-down(medium) {
        width: 100%;

      }

    }
  }
}
