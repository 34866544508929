@import "./variables.scss";

.BuyProducts {
  background-color: $background;
  display: flex;
  justify-content: center;
  color: $white;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 150px $margen-lat 50px $margen-lat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint-down(medium) {
      padding: 50px $margen-lat-mobil 20px $margen-lat-mobil;

    }

    &--card {
      max-width: 1080px;
      background-color: $white;
      border-radius: 50px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      padding: 70px 70px 40px 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;

      @include breakpoint-down(medium) {
        padding: 30px;

      }


      &-content {
        display: flex;

        @include breakpoint-down(large) {


        flex-direction: column;

        }


        &-left {
          width: 45%;

          @include breakpoint-down(large) {
            width: 100%;
          }


          &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 45px;

            height: 300px;
            width: auto;
            overflow: hidden;
            border-radius: 25px;

            position: relative;

            @include breakpoint-down(large) {
              max-height: 300px;
              height: auto;
              width: 100%;
              margin-bottom: 20px;
              
            }


            a {
              position: absolute;
              top: 0;
              right: 0;
              width: 100px;
              padding: 3px;
              border-radius: 3px;
              background-color: $secundary;
              font-size: 14px;
              color: $white;
              text-align: center;
            }

            img {
              top: 0;
              right: 0;
              width: auto;
              height: 100%;
              border-radius: 25px;

              @include breakpoint-down(large) {
                height: auto;
                width: 100%;
              }


            }
          }

          h4 {
            font-size: $header_title;
            line-height: 1.22;
            color: $secundary;
            font-weight: bold;
            margin-bottom: 40px;
            text-align: left;
          }

          h3 {
            font-size: $header_title;
            line-height: 1.22;
            color: $black;
            font-weight: bold;
            margin-bottom: 40px;
            text-align: left;

            span:nth-child(2) {
              font-style: italic;
              font-size: $card_title2;
            }
          }

          p {
            font-size: $card_title2;
            line-height: 1.22;
            color: $black;
            text-align: left;
            margin-bottom: 22px;
          }

          p::before {
            content: "\2022";
            margin-right: 15px; /* Espaciado opcional entre el punto y el texto */
          }
        }
        &-right {
          width: 55%;
          padding: 0 60px;




          @include breakpoint-down(large) {
            width: 100%;
            padding: 0 30px;
            
          }

          @include breakpoint-down(medium) {
            padding: 0;
          }


          p:nth-child(1) {
            font-size: $card_sub;
            line-height: 1.1;
            color: $black;
            text-align: left;
            margin-bottom: 28px;
          }

          p:not(:first-of-type) {
            font-size: $card_sub;
            line-height: 1.4;
            color: $black;
            text-align: left;
            margin-bottom: 20px;

            &::before {
              content: "\2713";
              margin-right: 10px;
              padding: 1px 5px;
              background-color: rgb(2, 188, 125);
              color: white;
              border-radius: 100%;
            }
          }

          &-button {
            max-width: 650px;
            width: 100%;
            height: 60px;
            border-radius: 15px;
            margin-top: 22px;
            background-color: $secundary;
            font-size: 28px;
            color: $white;
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &--purchase {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            margin-top: 30px;
          
            &-text {
              display: inline; // O usa inline-block si necesitas
              margin-right: 5px; // Espacio entre el texto y el enlace
              font-size: 14px;
              color: #555;
            }
          
            &-footer__policies {
              font-size: 14px;
              color: #007bff;
              text-decoration: none;
          
              &:hover {
                text-decoration: underline;
              }
            }
      
      
          }



        }
      }

    }
  }
}
