@import "./variables.scss";

.indicatorsSec{
    background-color: $back-light;
    display: flex;
    justify-content: center;
    color: $black;


    &__content {
        max-width: $max-width;
        width: 100%;
        padding: 0 $margen-lat 0 $margen-lat;
        display: flex;
        flex-direction: column;

        @include breakpoint-down(medium) {
            padding: 0 $margen-lat-mobil 0 $margen-lat-mobil;

        }

        &--h2{
            font-size: $sec_title;
            text-align: center;
            color: $black;
            margin-top: 100px;
            max-width: 1000px;
            align-self: center;

            @include breakpoint-down(medium) {
                margin-top: 60px;
                font-size: $header_title;

            }


        }

        &--h3{
            font-size: $sec_sub;
            text-align: center;
            color: $black;
            line-height: 1.49;
            margin-top: 20px;
            max-width: 1050px;
            align-self: center;
            margin-bottom: 125px;

            @include breakpoint-down(large) {
                margin-bottom: 70px;
            }

        }

        &--cont{
            display: flex;

            @include breakpoint-down(large) {
                flex-direction: column;
            }

        }

    }

}