@import "./variables.scss";

.noClose {
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px $margen-lat 20px $margen-lat;

  @include breakpoint-down(large) {
    padding: 20px;
  }

  &-msg {
    width: 80%;
    max-width: 1000px;
    background-color: $secundary;
    color: $white;
    border-radius: 50px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $card_title2;
    padding: 20px 40px;

    @include breakpoint-down(large) {
      width: 90%;
      height: 180px;
      padding: 20px 10px;
      text-align: center;
    }

    @include breakpoint-down(medium) {
      width: 100%;
      height: 180px;
      padding: 20px 10px;
      text-align: center;
    }
  }
}

.formLic {
  background-color: $background;
  display: flex;
  justify-content: center;
  color: $white;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 90px $margen-lat 50px $margen-lat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;

    @include breakpoint-down(large) {
      padding: 20px 20px 30px 20px;
    }

    &--form {
      background-color: $white;
      display: flex;
      flex-direction: column;
      width: 80%;
      max-width: 1000px;
      border-radius: 45px;
      padding: 90px 50px;
      margin-bottom: 150px;

      @include breakpoint-down(large) {
        width: 90%;
        margin-bottom: 50px;
      }

      @include breakpoint-down(medium) {
        width: 100%;
        padding: 20px 20px;
      }

      &-title {
        font-size: $header_title;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;

        @include breakpoint-down(medium) {
          margin-bottom: 50px;
        }
      }

      &-subt {
        font-size: $sec_sub;
        text-align: center;
        margin-bottom: 80px;

        @include breakpoint-down(medium) {
          margin-bottom: 50px;
        }
      }

      &-subp {
        font-size: $card_title2;
        text-align: left;
        margin-bottom: 20px;

        @include breakpoint-down(medium) {
          margin-bottom: 50px;
        }
      }

      &-licImg {
        width: 100%;
        margin-bottom: 30px;

        img {
          height: auto;
          width: 95%;
        }
      }

      label {
        font-size: $card_title2;
        text-align: left;
        margin-bottom: 10px;
      }

      input {
        background-color: $white;
        border: 1px solid $black;
        width: 100%;
        height: 70px;
        border-radius: 15px;
        margin-bottom: 50px;
        font-size: 24px;
        padding: 0 15px;

        @include breakpoint-down(medium) {
          margin-bottom: 20px;
          height: 60px;
        }
      }

      textarea {
        background-color: $white;
        border: 1px solid $black;
        width: 100%;
        height: 150px;
        border-radius: 15px;
        margin-bottom: 50px;
        resize: vertical;
        font-size: 24px;
        padding: 15px;

        @include breakpoint-down(medium) {
          height: 100px;
        }
      }

      button {
        width: 100%;
        height: 60px;
        border-radius: 15px;
        margin-top: 22px;
        background-color: $secundary;

        font-size: 28px;
        color: $white;
      }

      &-indications {
        margin-top: 20px;

        p {
          font-size: 20px;

          &:nth-of-type(-n + 3) {
            font-weight: normal;
            margin-bottom: 15px;

            &:before {
              content: "•";
              margin-right: 5px;
            }

          }
          &:nth-last-of-type(-n + 2) {
            font-style: italic;
            margin-bottom: 5px;



          }

        }
      }
    }

    &--sending {
      font-size: 42px;
      text-align: center;
      padding: 40px 20px;
    }

    &--fail {
      font-size: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 100px;

      @include breakpoint-down(medium) {
        font-size: 22px;
        margin: 20px;
      }

      p {
        background-color: rgb(255, 127, 161);
        padding: 50px;
        border-radius: 25px;

        @include breakpoint-down(medium) {
          padding: 20px;
        }
      }
    }

    &--succes {
      font-size: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 100px;

      @include breakpoint-down(medium) {
        font-size: 22px;
        margin: 20px;
      }

      p {
        background-color: aquamarine;
        padding: 50px;
        border-radius: 25px;

        @include breakpoint-down(medium) {
          padding: 20px;
        }
      }
    }
  }
}
