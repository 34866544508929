@import './variables.scss';

.layout{
    // background-color: crimson;
    &__content{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: chocolate;
    }
}


