@import "./variables.scss";

.indicatorlist {
  width: 70%;

  @include breakpoint-down(large) {
    width: 100%;
    

  }  
  
    &__content{  

      @include breakpoint-down(large) {
        display: flex;
        flex-direction: column;
        align-items: center;

      }


      &--card{
        width: 92%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        margin-bottom: 80px;
        border-radius: $border-card;
        cursor: pointer;
        overflow: hidden;

        @include breakpoint-down(large) {
          margin-bottom: 40px;

        }



        &-img{
          border-radius: $border-card;


          img{
            width: 100%;
            height: auto;
            display: block;
            min-height: 450px;



          }


        }


        &-content{

          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(10, 10, 10, 0.7); 
          color: $white;
          padding: 10px 100px 40px 50px;
          border-radius: 0 0 $border-card $border-card;





          @include breakpoint-down(large) {
          padding: 10px 20px 20px 20px;
          }

          h3{
            font-size: $card_title;
            font-weight:bold;
            margin-bottom: 30px;

            @include breakpoint-down(large) {
              margin-bottom: 10px;

            }

          }

          p{
            font-size: 1rem;
            font-weight:500;
          }


        }


      }

  
  }
}
