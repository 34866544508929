@import "./variables.scss";

.education-page {
  background-color: $background;
  display: flex;
  justify-content: center;
  color: $white;

  &__container {
    max-width: $max-width;
    width: 100%;
    padding: 90px $margen-lat 50px $margen-lat;
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(medium) {
      padding: 30px $margen-lat-mobil 20px $margen-lat-mobil;
      flex-direction: column-reverse;
    }

    &--blog {
      width: 65%;

      @include breakpoint-down(medium) {
        width: 100%;
      }

    }
  }
}
