@import "./variables.scss";
@import "./mixins";

.burguer {

    position: absolute;
    right: 23px;

    @include breakpoint-down(medium) {
      margin-top: 20px;
      margin-right: 10px;

      :nth-child(n) {
        background-color: $white;
        height: 5px;
        width: 35px;
        margin: 5px;
      }
    }
}

.sideBarOn,
.sideBarOff {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
  height: 500px;
  background-color: $back-mid;
  border-radius: 18px;
  z-index: 999;

  // closeButton -------
  .st1 {
    position: absolute;
    top: 8px;
    right: 10px;

    height: 35px;
    width: 35px;
  }

  .st2,
  .st3 {
    position: absolute;
    top: 10px;
    right: -5px;

    height: 5px;
    width: 35px;
    margin: 5px;

    background-color: $white;
  }

  .st2 {
    rotate: (45deg);
  }

  .st3 {
    rotate: (-45deg);
  }
  // ----------------

  & .sideBarLinks {
    display: flex;
    flex-direction: column;
    font-size: 30px;
    font-weight: bold;

    margin-top: 83px;
    margin-left: 35px;

    & a {
      margin: 12px 0px;
      color: $white;
    }
  }
  // ----------------

  animation-duration: 750ms;
}

.sideBarOn {
  margin-left: 0%;
  animation-name: slideIn;
  transform: translateX(0%);
}

.sideBarOff {
  margin-left: 200%;
  animation-name: slideOut;
  transform: translateX(0%);
}

@keyframes slideIn {
  from {
    margin-left: 200%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slideOut {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 200%;
  }
}

.sideBarInit {
  margin-left: 0%;

  .st1,
  .st2,
  .st3,
  .sideBarLinks {
    display: none;
  }
}
