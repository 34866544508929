@import "./variables.scss";

.advantages {
  background-color: $background;
  color: $white;
  display: flex;
  justify-content: center;

  &__content {
    max-width: $max-width;
    width: 100%;
    padding: 0 $margen-lat 0 $margen-lat;
    display: flex;
    flex-direction: column;

    @include breakpoint-down(medium) {
      padding: 0 $margen-lat-mobil 0 $margen-lat-mobil;
    }

    &--h2 {
      font-size: $sec_title;
      text-align: center;
      margin-top: 100px;
      max-width: 1050px;
      align-self: center;
    }
    &--h3 {
      font-size: $sec_sub;
      text-align: center;
      line-height: 1.49;
      margin-top: 20px;
      max-width: 1050px;
      align-self: center;
      margin-bottom: 125px;
    }
    &--cards {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

        justify-content: center;



      &-card {
        border-radius: 15px;
        color: $black;

        margin: 0 20px;
        width: 620px;
        margin-bottom: 100px;

        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);         


        @include breakpoint-down(medium) {
          width: 100%;
          margin-bottom: 50px;
        }

        &-img {
          width: 620px;
          height: 320px;
          border-radius: 15px 15px 0 0;
          overflow: hidden;

          background: linear-gradient(
            0deg,
            #ff4b95 0%,
            #ff637b 50%,
            #ff6875 100%
          );

          display: flex;
          justify-content: center;
          align-items: center;

          @include breakpoint-down(medium) {
            width: 100%;
            height: auto;

          }


          img {
            width: 90%;
            display: block;
            margin: auto;
          }
        }

        &-content {
          background-color: $white;
          border-radius: 0 0 15px 15px;
          padding: 45px;
          min-height: 290px;

          @include breakpoint-down(medium) {
            padding: 15px;
          }


          &-title {
            font-size: $card_title2;
            text-align: center;
            align-self: center;
            font-weight: bold;
          }
          
          &-parag {
            font-size: $card_sub;
            text-align: center;
            line-height: 1.49;
            align-self: center;
          }
        }
      }
    }
  }
}
