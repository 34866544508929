@import "./variables.scss";

.edSidebar {
  width: 30%;
  height: 100%;
  position: relative;

  @include breakpoint-down(medium) {
    width: 100%;
      
  }

  &__content {
    position: sticky;
    top: 20px;
    margin-bottom: 80px;
    right: 0;

    padding: 30px 20px 50px 20px;
    background-color: $back-light;
    color: $black;
    border-radius: $border-card;

    @include breakpoint-down(medium) {
      margin-bottom: 40px;

    }

    &--susc {
      h3 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 20px;
      }

      a {
        display: inline-block;
        font-size: 15px;
        width: 100%;
        margin-top: 20px;
        text-align: left;
        cursor: pointer;
      }


      a::before {
        content: "\2022"; 
        margin-right: 0.5em; 
      }

      p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 1.2;
        text-align: left;
      }
    }
    &--blog {
      margin-top: 80px;
      cursor: pointer;

      h3 {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
      }

      &-card {
        margin-top: 20px;
        border-radius: $border-card;
        background-color: $back-mid;
        padding: 20px;

        h4 {
          font-size: 22px;
          font-weight: bold;
          line-height: 1.1;
          text-align: center;
        }
        p {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.2;
          text-align: left;
        }
      }
    }
  }
}
