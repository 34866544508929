@import "./variables.scss";

.installation {
  background-color: $background;
  
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: $max-width;
    width: 100%;
    padding: 90px $margen-lat 50px $margen-lat;

    color: $back-light;

    @include breakpoint-down(medium) {
      padding: 90px $margen-lat-mobil 50px $margen-lat-mobil;

    }


    h2 {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.5;
      color: $white;
      margin-bottom: 40px;

      @include breakpoint-down(medium) {
        font-size: 24px;
        margin-bottom: 30px;
      }

    }




    h3 {
      font-size: 24px;
      line-height: 1.5;
      color: $white;
      margin-bottom: 20px;

      @include breakpoint-down(medium) {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
      }

    }




    p{
      font-size: 18px;
      line-height: 1.5;
      color: $white;
      text-align: left;
      margin-bottom: 70px;   
      
      @include breakpoint-down(medium) {
        font-size: 16px;
        margin-bottom: 40px;
      }

    }



    &--video {
      width: 80%;
      margin-bottom: 125px;

      @include breakpoint-down(medium) {
        width: 100%;
        margin-bottom: 50px;
      }

      iframe{
        width: 100%;
        height: 650px;

        @include breakpoint-down(medium) {
          height: 250px;
        }

      }

    }
  }
}
